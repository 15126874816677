import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Ahp = (props) => (
    <header id="ahp" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <Link to="/"><span className="icon fa-book"></span></Link>
        </div>
        <div className="content">
            <div className="inner">
            <h1>New Haven Speculative Fiction Workshop</h1>
            <h3>Anti-Harassment Policy</h3>
            
                <p><strong>What you can't do:</strong></p>

                <p>Any behavior that makes another person feel unsafe, harms another <br />
                person, or causes another person distress is expressly forbidden.</p> 

                <p>Deliberately and persistently annoying someone, offering unwelcome <br />
                criticism outside of the context of constructively critiquing stories, <br />
                and any kind of intimidation or threatening behavior is expressly <br />
                forbidden.</p> 

                <p>Any harassing comment or attack based on age, citizenship status, <br />
                disability, familial status,  gender, gender expression or identity,<br />
                geography, marital status, place of origin, race/ethnicity, religion, <br />
                sexual orientation, or socioeconomic status is expressly forbidden.</p> 

                <p>Any form of sexual harassment, regardless of circumstance or parties <br />
                involved, is expressly forbidden.</p>

                <p>If you feel that someone's participating in harassing behavior, please <br />
                contact the organizer with your concerns immediately. All complaints will <br />
                remain anonymous.</p>

                <p>Conversely, if you're asked to stop a particular behavior, it is <br />
                expected that you do so immediately. Egregious or continued harassment <br />
                will result in expulsion from the group.</p>

                <p>Okay?</p>

                <p><strong>What you are allowed to do:</strong></p>
                <p>You are allowed to do your part in creating an atmosphere of tolerance <br />
                and acceptance. You are allowed to provide constructive feedback that helps <br />
                each participant grow as a writer. You are allowed to expect the same from <br />
                others. Turn that frown upside down.</p>

                <p><strong>Finally:</strong></p>

                <p>This page isn't meant to be an exhaustive list of dos and don'ts. We <br />
                kindly ask that you use your brain. It's still harassment if you're "just <br />
                joking." It's still harassment if it's subtext and innuendo. It's still <br />
                harassment whether scribbled in a notebook or written in the sky. If you're <br />
                not sure what counts, there are many resources online, but err in all cases <br />
                on the side of caution.</p> 
                
                <p><small>This anti-harassment policy was largely cribbed from that of the <br />
                non-profit group <a href="https://blog.americansforthearts.org/about-americans-for-the-arts/organizational-policies">Americans for the Arts</a>.</small> <br />
                We aren't affiliated in any way.</p>

                <p><Link to="/">Go back to the homepage</Link></p>
            </div>
        </div>
        <nav>
            <ul>
                <li><Link to="/introduction">About</Link></li>
                <li><Link to="/members">Members</Link></li>
                <li><Link to="/rules">Rules</Link></li>
                <li><Link to="/contact">Contact</Link></li>
            </ul>
        </nav>
    </header>
)

Ahp.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Ahp




 
